@use '../../../styles/variables';

.sidebar_container {
    width: 310px;
    background: variables.$RedWine;
    height: calc(100vh - 68px);
    border-bottom-right-radius: variables.$CardRadius;
    box-shadow: 2px 6px 6px rgba(33, 37, 41, 0.2), 0 1px 1px rgba(33, 37, 31, 0.32);
    position: relative;
    padding: 32px 32px 32px 30px;
    z-index: 16;
    // display: flex;
    // flex-direction: column;
    // flex-shrink: 0;

    position: fixed;
    top: 60px;
    display: flex;
    flex-direction: column;

    &_action {
        width: 100%;
    }
    &::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        background: transparent;
        top: 0px;
        right: -40px;
        border-radius: 40px;
        box-shadow: -20px -20px 0 0 #64242E;
    }
    &_navigation {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        gap: 22px;
        padding-bottom: 22px;
    }
    &_primary,
    &_secondary {
        display: flex;
        flex-direction: column;
        gap: 22px;
    }
    
    &_primary {
      margin-bottom: auto;
    }

    &_secondary {
      margin-bottom: -12px;
      gap: 12px;
    }
    
}

  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: variables.$RedWine;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px;
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-content a {
    margin: 7px 0;
    padding:0;
  }