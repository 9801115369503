$FontPath: "/assets/fonts" !default;
$Version: "1.0" !default;

$SelectWhite: rgb(255, 255, 255);
$SelectWhite80: rgba(255, 255, 255, 0.8);

$White25: rgba(243, 239, 245, 0.25);
$White50: rgba(243, 239, 245, 0.5);
$White75: rgba(243, 239, 245, 0.75);

$White: rgb(243, 239, 245);
$Black25: rgba(6, 11, 15, 0.25);
$Black50: rgba(6, 11, 15, 0.5);
$Black75: rgba(6, 11, 15, 0.75);
$Black: #060b0f;
$RedWine: rgb(100, 36, 46);
$RedWine5: rgba(100, 36, 46, 0.05);
$RedWine10: rgba(100, 36, 46, 0.10);
$RedWine25: rgba(100, 36, 46, 0.25);
$RedWine50: rgba(100, 36, 46, 0.5);
$RedWine75: rgba(100, 36, 46, 0.75);
$RedShadow: #4F171F;


$Cta: rgb(100, 36, 46);
$Cta15: rgba(144, 27, 58, 0.15);
$Cta25: rgba(144, 27, 58, 0.25);
$Cta50: rgba(144, 27, 58, 0.5);
$Cta75: rgba(229, 188, 3, 0.75);
$CtaNewOperation: rgba(229, 120, 149, .33);
$CtaNewOperationHover: rgba(223, 106, 138, .75);

// $RoseWine: #ae464f;
$RoseWine: rgb(199, 79, 89);
$RoseWine15: rgba(199, 79, 89, 0.15);
$RoseWine25: rgba(199, 79, 89, 0.25);
$RoseWine75: rgba(199, 79, 89, 0.75);
$RoseWine50: rgba(199, 79, 89, 0.5);
$RoseShadow: rgb(165, 58, 67);

$LightRedWine: #ab8991;
$RedError: rgb(187, 68, 86);
$RedError65: rgb(194, 86, 102, 0.65);
$RedError55: hsl(351, 47%, 55%);
$RedExpireDateCard: rgb(240 90 90);
$RedError50: rgb(187, 68, 86, .50);

$WarningError: rgb(255, 165, 0);

// $WhiteWineOLD: rgb(172, 132, 0);
$WhiteWine: rgb(249, 213, 115);
$WhiteWine50: rgba(249, 213, 115, 0.5);
$WhiteWine75: rgba(249, 213, 115, 0.75);
$WhiteWine30: rgba(249, 213, 115, 0.3);
$WhiteWine15: rgba(249, 213, 115, 0.15);

$LightGray: rgb(67, 66, 62);
$LighterGray: rgb(185, 184, 180);
$Lighter2Gray: rgb(207, 207, 207);
$CardLightGray50: rgba(185, 184, 180, 0.5);
$CardLightGray35: rgba(185, 184, 180, 0.35);
$CardLightGray25: rgba(185, 184, 180, 0.25);
$CardLightGray15: rgba(185, 184, 180, 0.15);

$TimelineArrowGray: rgb(170, 169, 165);

$Gold: rgb(247, 204, 60);
$Gold50: rgba(247, 204, 6, 0.5);
$Gold75: rgba(247, 204, 6, 0.75);
$Gold30: rgba(247, 204, 6, 0.3);

$GrapeWineGreen: #7ab982;
$GrapeWineGreen50: rgb(122, 185, 130, 0.5);
$AddRowGreen: #4bbd7f;
$DarkerBlue: rgb(124 193 189);
$LightBlue: rgb(198, 227, 225);
$LighterBlue: rgb(232, 246, 245);

$LightWoodOLD: rgb(157, 126, 104);
$LightWood75OLD: rgb(157, 126, 104, 0.75);
$LightWood: rgb(194, 148, 103);
$LightWood25: rgb(194, 148, 103, 0.25);
$LightWood75: rgb(194, 148, 103, 0.75);
$DarkWood: rgb(147, 94, 40);
$DarkWood35: rgb(147, 94, 40, 0.35);
$DarkWood75: rgb(147, 94, 40, 0.75);

$GlassLightBlue20: rgba(198, 227, 225, 0.2);
$GlassLightBlue25: rgba(198, 227, 225, 0.25);
$GlassLightBlue50: rgba(198, 227, 225, 0.5);
$GlassLightBlue: rgba(198, 227, 225, 0.75);
$ShadowGray: rgba(33, 37, 31, 0.32);
$ShadowGray75: rgba(33, 37, 31, 0.75);
$ShadowGray100: rgba(33, 37, 31, 1);

$CardRadius: 20px;
$ButtonRadius: 4px;

$CardShadow: 0 4px 6px rgba(33, 37, 41, 0.2), 0 0 1px rgba(33, 37, 31, 0.32);

$ShadowCoord: 2px 2px 4px 2px;
$HoverShadowCoord: 5px 5px 7px 3px;
$ActiveShadowCoord: 1px 1px 3px 1px;
$DisabledShadowCoord: 0 0 3px 0;

$ShadowInsetCoord: 4px 4px 6px 1px;
$HoverShadowInsetCoord: 4px 4px 6px 5px;
$ActiveShadowInsetCoord: 0 0 5px 0;
$DisabledShadowInsetCoord: 0 0 2px 0;

$Blur: blur(10px);
$Blur30: blur(30px);

$Padding: 32px;
$Margin: 32px;

$FontStack: "jost", "arial", "sans-serif";

$TransitionTime: 400ms;
$TransitionMode: ease-in-out;

$ChatBorder: #0000000d;
$ChatTime: #0006;
