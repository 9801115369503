@use '../../../../styles/variables';

.base_modal {
    &::backdrop {
        background: transparent;
        cursor: not-allowed;
        // backdrop-filter: variables.$Blur;
    }

    .modal_container {
        width: 100%;
    }
    
    backdrop-filter: variables.$Blur;
    border-radius: variables.$CardRadius;
    // border: 2px solid variables.$LightBlue;
    // border: 2px solid variables.$CardLightGray50;
    // background-color: variables.$GlassLightBlue;
    background-color: variables.$CardLightGray25;
    border: none;
    box-shadow: variables.$CardShadow;
    padding: variables.$Padding;
    min-height: 30%;
    max-height: 50%;

    width: 30%;
    min-width: 30%;
    max-width: 60%;
    overflow: auto;

    &.is_winery_selector {
        width: 80%;
        height: 60%;    
    }

    h4 {
        margin-bottom: 32px;
    }

    &.confirmation {
        // background-color: variables.$GlassLightBlue25;
        
        &.recap {
            // min-height: 60%;
            max-height: 90%;
            width: 60%;
            overflow: auto;
            // min-width: 60%;
            // max-width: 0%;
        }
    }

}

.confirmation-modal-container {
    white-space: pre-line;
    
    .login_title {
        margin-bottom: 0;
    }

    .login_wrapper {
        display: flex;
        gap: 16px;
    }

    .operation_info_container_row {
        margin-top: -20px;
    }

    .description {
        // margin: 5%;
    }

    .description2 {
        margin-top: 12px;
    }

    .button-container {
        margin-top: 20px;
        display: flex;
        gap: 16px;
        align-items: center;
        // align-items: flex-start;
        justify-content: space-between;

    }
}