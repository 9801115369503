@use "../../../styles/variables";

.list-container {
  margin-top: 10px;
  margin-bottom: 40px;

  .list_header_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: -60px;
    margin-bottom: 20px;

    .card_filters_wrapper {
        display: flex;
        width: 80%;
        margin-left: auto;
        margin-right: 16px;
    }
  }

  .list-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;

    .list-element {
      width: calc(100% / 3 - 16px);


      @media all and (((min-width: 481px) and (max-width: 767px)) or ((min-width: 320px) and (max-width: 480px))) {
        width: 100%;  
      }

      .element-card-container {

        &.clickable {
          cursor: pointer;
          &:hover {
            // background: variables.$RedWine25;
          }
        }
        
        .priority_tag_wrapper {
          width: 100%;
          
          .priority_tag {
            margin-left: auto;
            display: flex;
            order: 1;
            margin-right: 10px;
            height: 16px;
            text-transform: uppercase;
            color: #f3eff5;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            max-width: 180px;
            text-align: center;
            width: -moz-fit-content;
            width: fit-content;
            padding: 2px 10px;
            font-size: 12px;
            
            &.high {
              background-color: variables.$RedError;
            }
            &.medium {
              background-color: variables.$WhiteWine;
              color: variables.$Black;
            }
            &.low {
              background-color: variables.$Black50;
            }
          }
        }

        .element-card {
          border-radius: 4px;
          box-shadow: 0 4px 6px rgb(33 37 41 / 20%), 0 0 1px rgb(33 37 31 / 32%);
          display: flex;
          // align-items: center;
          // justify-content: space-around;
          padding: 16px;
          width: 100%;
          height: 100px;
          min-width: 250px;
          border-left: 6px solid variables.$AddRowGreen;
          background-color: variables.$White;

          &:hover {
            cursor: pointer;
            box-shadow: variables.$HoverShadowCoord variables.$ShadowGray;

            &.red {
              background-color: variables.$RedWine5;
            }
            &.white {
              background-color: variables.$WhiteWine15;
            }
            &.rose {
              background-color: variables.$RoseWine15;
            }
          }
  
          &.expired {
            border-left: 6px solid variables.$RedExpireDateCard;
          }

          &.red {
            border-left: 6px solid variables.$RedWine;
          }
          &.white {
            border-left: 6px solid variables.$WhiteWine;
          }
          &.rose {
            border-left: 6px solid variables.$RoseWine;
          }

          .card-content {
            width: 100%;

            .upper {
              width: 100%;
              display: flex;
              flex-wrap: nowrap;
              align-items: flex-start;
              gap: 16px;

              .title {
                font-size: 16px;
                margin: 0;
                font-weight: 600;
                margin-bottom: 8px;
              }

              .info_wrapper {
                margin-left: auto;

                .date {
                  font-size: 14px;

                  .icon {
                    margin-right: 5px;
                  }

                  &.expired {
                    color: variables.$RedExpireDateCard;
                    font-weight: 500;
                  }
                }
              }
            }

            .lower {
              margin-left: 10px;

              .info_wrapper {
                display: flex;
                gap: 16px;
                flex-wrap: nowrap;
                flex-direction: row;
                  
                .src_tanks {
                  flex: 45%;

                  .tank_name {
                    font-size: 14px;
                  }
                }

                .icon-wrapper {
                  flex-grow: 1;
                  width: 80px;
                  height: 75px;
                  margin-top: -15px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: -12px;

                  .icon {
                    width: 100%;
                    filter: opacity(0.10);
                    margin-top: 6px;
                  }
                }

                .cellarmen {
                  flex: 45%;
                  margin-left: auto;
                  text-align: right;
                  font-style: italic;

                  .cellarman_name {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.gauge-container {
  width: 200px;
  height: 100px;
  position: relative;
}

.gauge-semi-circle {
  width: 200px;
  height: 100px;
  border-radius: 100px 100px 0 0; // Create a semi-circle
  position: absolute;
  bottom: 0;
  background-color: green; // Default color

  &.green {
    background-color: #5BE12C; // Inside the range
  }

  &.red {
    background-color: #EA4228; // Outside the range
  }
}

.gauge-needle {
  position: absolute;
  width: 4px; // Width of the needle
  height: 100px; // Height of the needle
  background-color: black; // Color of the needle
  transform-origin: bottom; // Rotate around the bottom
}

.gauge-label {
  position: absolute;
  top: -30px; // Position above the gauge
  left: calc(50% - 15px); // Centered horizontally
}

.table-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 30px;
}

table {
  border-collapse: collapse;
  margin: 25px 0;
  box-shadow: variables.$CardShadow;
  width: 100%;
}

table thead tr {
  background-color: variables.$RedWine;
  color: variables.$White;
  font-weight: 600;
  text-align: left;
  // cursor: pointer;
  cursor: default;

  .sort {
    cursor: pointer;
  }
}

table th,
table td {
  padding: 10px 15px;
  vertical-align: middle;

  .table-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .table-header-value {
      flex-grow: 2;
    }

    .table-header-sorting {
    }
  }
}

table tbody tr {
  &.clickable {
    cursor: pointer;
    &:hover {
      background: variables.$RedWine25;
    }
  }

  &.tankRowSpan {
    background: linear-gradient(variables.$White, variables.$White) padding-box,
      linear-gradient(
          to left,
          transparent,
          variables.$RedWine25,
          variables.$RedWine50
        )
        border-box;
    border-top: 2.5px solid transparent;
  }

  &.rowSpan {
    background: linear-gradient(variables.$White, variables.$White) padding-box,
      linear-gradient(to left, variables.$RedWine75, variables.$RedWine50)
        border-box;
    border-top: 3px solid transparent;
  }

  .quantity {
    // color: variables.$RedWine;
    font-weight: 500;

    // &.is_ok {
    //   background-color: variables.$AddRowGreen;
    //   padding: 2px 16px;
    //   border: 1px solid variables.$AddRowGreen;
    //   border-radius: 5px;
    // }

    // &.is_warning {
    //   background-color: variables.$WarningError;
    //   padding: 2px 16px;
    //   border: 1px solid variables.$WarningError;
    //   border-radius: 5px;
    // }
  }
}

table tbody tr:nth-of-type(even) {
  background-color: variables.$RedWine5;
}

table tbody tr:last-of-type {
  border-bottom: 2px solid variables.$RedWine;
}

table tbody tr.active-row {
  font-weight: bold;
  color: variables.$RedWine;
}

table td span {
  &.note {
    cursor: pointer;
    display: block;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.additional {
    font-size: 13px;
    margin-left: 12px;
  }

  &.additional_span {
    line-height: 20px;
  }

  &.hide {
    display: none;
  }

  &.highlight {
    font-size: 17px;
    font-weight: 500;
  }

  &.expired {
    color: variables.$RedError;
  }
}

table td:first-child input {
  width: 60px;
}

table td:nth-child(2) input {
  width: 120px;
}

table .edit-cell-container button {
  border-radius: 50px;
  height: 26px;
  width: 26px;
  border: 1px solid #ccc;
}

table .footer-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7px;

  .total-cost {
    font-weight: 500;
    font-size: 18px;
    margin-right: 12px;
  }
}

table .add-button {
  color: #4bbd7f;
}

table .remove-button {
  color: variables.$RedError;
  font-size: 16px;
}

table .footer-buttons button {
  border: none;
  background-color: transparent;
}

table .edit-cell-action button[name="edit"] {
  color: variables.$Black75;
  padding: 0%;
  background-color: variables.$GlassLightBlue;
}

table .edit-cell-action button[name="cancel"] {
  color: #7b7b7b;
  padding: 0%;
  background-color: variables.$Gold30;
}

table .edit-cell-action button[name="done"] {
  color: #4bbd7f;
  padding: 0%;
  background-color: variables.$Gold30;
}

table .edit-cell-action button[name="remove"] {
  color: variables.$RedError;
  padding: 0%;
  background-color: rgb(230, 208, 208);
}

table input,
select {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
}

table .edit-cell-action {
  display: flex;
  gap: 5px;
}

table .edit-cell-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

table input[type="checkbox"] {
  width: 16px;
  height: 16px;
  accent-color: variables.$RedWine;
}

.box-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  .right-box {
    order: 2;
    margin-left: auto;

    .filter-box {
      .btn-filter {
        display: flex;
        gap: 10px;
      }
    }
  }

  .left-box {
    // order: 1;
    // width: 50%;

    .search-box {
      width: fit-content;
      height: fit-content;
      position: relative;
    }

    .input-search {
      height: 50px;
      width: 50px;
      border-style: none;
      padding: 10px;
      font-size: 18px;
      letter-spacing: 2px;
      outline: none;
      border-radius: 25px;
      transition: all 0.5s ease-in-out;
      background-color: variables.$RedWine;
      padding-right: 40px;
      color: variables.$Black;
    }
    .input-search::placeholder {
      color: variables.$RedWine;
      font-size: 15px;
      letter-spacing: 2px;
      font-weight: 400;
    }
    .btn-search {
      width: 50px;
      height: 50px;
      border-style: none;
      font-size: 20px;
      font-weight: bold;
      outline: none;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      right: 0px;
      color: variables.$White;
      background-color: transparent;
      pointer-events: painted;
    }
    .btn-search:focus ~ .input-search {
      width: 300px;
      border-radius: 0px;
      background-color: transparent;
      border-bottom: 1px solid variables.$RedWine;
      transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
    }
    .input-search:focus {
      width: 300px;
      border-radius: 0px;
      background-color: transparent;
      border-bottom: 1px solid variables.$RedWine;
      transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination-items {
  display: flex;
  gap: 16px;
  align-items: center;

  .fix-height {
    width: 100px;
    height: fit-content;
  }

  .input-page {
    width: 100px;
  }
  
  .current-page {
    width: auto;
    display: flex;
    flex-wrap: nowrap;
  }
}


.filters {
  cursor: default;
  // width: 100%;
  text-align: end;
  flex-grow: 1;

  .dropbtn {
    padding: 16px;
    font-size: 16px;
    border: none;
  }

  .dropdown-filter {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .icon {
    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-filter-content {
    position: absolute;
    background-color: variables.$White;
    min-width: 160px;
    padding: 10px 5px;
    border-radius: 5px;
    border-top-right-radius: 0px;
    width: fit-content;
    box-shadow: variables.$CardShadow;
    right: 10px;
    display: none;
    z-index: 10;

    &.open {
      display: block;
    }

    .range {
      input {
        width: 100%;
        margin-top: 5px;
      }
    }

    li {
      color: variables.$Black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;

      &.all {
        border-bottom: 2px solid variables.$RedWine25;

        &.disabled {
          color: variables.$Black25;

          &:hover {
            cursor: default;
            background-color: variables.$White;
          }
        }
      }

      &.active {
        color: variables.$White;
        background-color: variables.$RedWine;
      }

      &:hover {
        color: variables.$White;
        background-color: variables.$RedWine50;
      }
    }
  }
}