@use "../../../styles/variables";

.operation_info_container {
  // &.analysis_tanks {
  //   display: flex;
  //   flex-wrap: wrap;
  //   column-gap: 150px;
  // }

  /* Large or high resolution screen */
  @media all and (max-width: 1690px) {
  }

  /* Desktop or laptop */
  @media all and (min-width: 1280px) and (max-width: 1689px) {
  }

  // /* Tablet in horizontal view */
  // @media all and (min-width: 737px) and (max-width: 1279px) {
  // }

  // /* Tablet in vertical view */
  // @media all and (min-width: 481px) and (max-width: 736px) {
  // }

  /* Smartphone or small tablet */
  //   @media all and (max-width: 480px) {
  //     display: flex;
  //     flex-direction: column;
  //   }

  &_title {
    display: flex;
    align-items: center;

    h4 {
      flex-grow: 1;
    }
  }

  &_row {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: 16px;

    &.start {
      align-items: flex-start;
    }

    // /* Large or high resolution screen */
    // @media all and (max-width: 1690px) {
    // }

    // /* Desktop or laptop */
    // @media all and (min-width: 1280px) and (max-width: 1689px) {
    // }

    // /* Tablet in horizontal view */
    // @media all and (min-width: 737px) and (max-width: 1279px) {
    // }

    // /* Tablet in vertical view */
    // @media all and (min-width: 481px) and (max-width: 736px) {
    // }

    // /* Smartphone or small tablet */
    // @media all and (max-width: 480px) {
    //   gap: 1px;
    // }

    &.additional {
      padding-top: 0;
      padding-bottom: 0;
      color: grey;
    }

    &_total {
      display: flex;
      align-items: center;
      margin-left: 16px;

      //   /* Large or high resolution screen */
      //   @media all and (max-width: 1690px) {
      //   }

      //   /* Desktop or laptop */
      //   @media all and (min-width: 1280px) and (max-width: 1689px) {
      //   }

      // /* Tablet in horizontal view */
      // @media all and (min-width: 737px) and (max-width: 1279px) {
      // }

      // /* Tablet in vertical view */
      // @media all and (min-width: 481px) and (max-width: 736px) {
      // }

      //   /* Smartphone or small tablet */
      //   @media all and (max-width: 480px) {
      //     margin-bottom: 30px;
      //     display: block;
      //   }
    }

    .init_wrapper {
      display: flex;
      align-items: center;

      //   /* Large or high resolution screen */
      //   @media all and (max-width: 1690px) {
      //   }

      //   /* Desktop or laptop */
      //   @media all and (min-width: 1280px) and (max-width: 1689px) {
      //   }

      // /* Tablet in horizontal view */
      // @media all and (min-width: 737px) and (max-width: 1279px) {
      // }

      // /* Tablet in vertical view */
      // @media all and (min-width: 481px) and (max-width: 736px) {
      // }

      //   /* Smartphone or small tablet */
      //   @media all and (max-width: 480px) {
      //     display: flex;
      //     align-items: center;
      //   }

      .total_drug_wrapper {
        display: flex;

        .costs {
          width: 100px;
        }

        .total_drug {
          width: 230px;

          ul {
            margin-top: 10px;
            margin-left: 10px;
          }

          li {
            margin-top: 3px;
          }
          // /* Large or high resolution screen */
          // @media all and (max-width: 1690px) {
          // }

          // /* Desktop or laptop */
          // @media all and (min-width: 1280px) and (max-width: 1689px) {
          // }

          // /* Tablet in horizontal view */
          // @media all and (min-width: 737px) and (max-width: 1279px) {
          // }

          // /* Tablet in vertical view */
          // @media all and (min-width: 481px) and (max-width: 736px) {
          // }

          // /* Smartphone or small tablet */
          // @media all and (max-width: 480px) {
          //   flex-grow: 1;
          //   min-width: 130px;
          //   margin-left: 20px;
          // }

          .total_drug_quantity, .total_drug_cost {
            .substance_quantity {
              font-weight: 500;
              font-size: 18px;
              margin-right: 12px;
            }
          }

          .total_drug_cost {
            margin-top: 5px;
            padding: 1px;
          }
        }
      }
    }

    // /* Large or high resolution screen */
    // @media all and (max-width: 1690px) {
    // }

    // /* Desktop or laptop */
    // @media all and (min-width: 1280px) and (max-width: 1689px) {
    // }

    // /* Tablet in horizontal view */
    // @media all and (min-width: 737px) and (max-width: 1279px) {
    // }

    // /* Tablet in vertical view */
    // @media all and (min-width: 481px) and (max-width: 736px) {
    // }

    // /* Smartphone or small tablet */
    // @media all and (max-width: 480px) {
    //   flex-wrap: wrap;
    // }
  }
  h3 {
    margin: 16px 0;
  }
  
  .arrow {
    min-width: 70px;
    text-align: center;
  }

  // .init_status {
  // }
  // .end_status {
  // }
  .tanks_wrapper {
    .tank_name {
      font-weight: 450;
      width: 150px;
      font-size: 19px;

      &.unset-width {
        width: unset;
      }
    }
  }

  .batch_name {
    font-weight: 400;

    &.bold {
      font-weight: 500;

      .new {
        color: red;
        font-weight: 600;
        font-size: 9px;
        position: absolute;
        margin-top: -8px;
        margin-left: 6px;
        text-transform: uppercase;
      }
    }
  }
  // .init_status,
  // .end_status {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   margin: 16px 0;
  //   width: 230px;
  //   text-align: center;

  //   &.barrel {
  //     &::before {
  //       display: block;
  //       position: relative;
  //       content: "";
  //       width: 5px;
  //       transform: rotate(90deg);
  //       height: 3px;
  //       background-color: variables.$DarkWood35;
  //       border-radius: 100% / 20%;
  //       top: 57px;
  //       transform: rotate(90deg);
  //       height: 3px;
  //       left: 20px;
  //     }
  //   }
  // }
  // .tanks_container {
  //   margin-left: 16px;
  // }
}

.operation_info_detail {
  margin: 16px 0;
}

.operation_destemming_wrapper {
}

.operation_bottling_wrapper {
  .tag-fix {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .bottling {
    width: 100%;
    // width: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .icons {
      align-items: center;
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      min-width: 30%;
      max-width: 40%;
      margin-left: -40px;

      .line {
        border-top: 1px solid variables.$Black;
        width: 150px;
        margin-top: 8px;
        margin-left: 13px;
        margin-right: 10px;
      }

      .bold-p {
        font-weight: 600;
      }

      .value-p {
        margin-left: 10px;
        font-weight: 400;
        text-align: right;
        width: 100%;
      }

      .cap_icon {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        margin-left: 50px;
      }

      .bottle_icon {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 20px;
        width: 100%;

        .icon_rotate {
          transform: rotate(-45deg);
        }

        .line {
          margin-top: 30px;
          margin-left: -10px;
        }

        .bold-p,
        .value-p {
          margin-top: 20px;
        }
      }
    }

    .box_icons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // width: fit-content;
      // min-width: 40%;
      max-width: 60%;

      .box_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 16px 0;
        margin-top: 20px;
        width: 200px;

        .batch_name {
          font-weight: 600;
          text-align: center;
        }

        .bottle_icon {
          top: 50px;
          transform: rotate(-45deg);
          position: relative;
        }
      }
    }
  }
}
.operation_automatic_wrapper {
  //   /* Large or high resolution screen */
  //   @media all and (max-width: 1690px) {
  //   }

  //   /* Desktop or laptop */
  //   @media all and (min-width: 1280px) and (max-width: 1689px) {
  //   }

  //   // /* Tablet in horizontal view */
  //   // @media all and (min-width: 737px) and (max-width: 1279px) {
  //   // }

  //   // /* Tablet in vertical view */
  //   // @media all and (min-width: 481px) and (max-width: 736px) {
  //   // }

  //   /* Smartphone or small tablet */
  //   @media all and (max-width: 480px) {
  //     display: flex;
  //     flex-direction: column;
  //   }
}

.analysis_table {
  // margin-top: 20px;
  width: 100%;

  .analysis_title,
  .analysis_switch {
    margin-bottom: 0px;
  }
}

.lot_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.recap {
    text-align: center;
    display: inline-block;
  }

  .lot_name {
    font-weight: 450;
    font-size: 19px;
    
    &.detail{
      width: 150px;
    }

    &.recap {
      width: unset;
    }
  
  }

  .lot_quantity {
    text-align: center;
    width: 180px;
  }
  
  h6 {
    text-align: center;
  }

  h6 {
    margin-bottom: -5px;
  }
}

.tanks_wrapper {
  .init_status {
    text-align: center;
    width: 180px;
  }

  .end_status {
    text-align: center;
    width: 180px;

    &.unset-width {
      width: unset;
    }

    .no_name {
      margin-top: 57px;
      // margin-left: 16px;
    }

    .centered {
      text-align: center;
    }
  }
  .tank_representation {
    width: 40px;
    height: 60px;
    background: variables.$GlassLightBlue;
    background: variables.$CardLightGray50;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 8px;
    // margin-top: 10px;
    // margin-left: 16px;

    display: flex;
    margin: 10px auto;
    max-width: 40px;
    margin-bottom: 10px;

    &.barrel {
      // width: 35px;
      // max-width: 35px;
      // height: 25px;
      // display: block;
      // background-color: variables.$LightWood25;
      border-radius: 100% / 20%;

      background: repeating-linear-gradient(
        0deg,
        variables.$LightWood25 5px,
        variables.$DarkWood35 5px,
        variables.$LightWood25 8px,
        variables.$LightWood25 30px
      );

      // &.tonneau {
      //     width: 58px;
      //     max-width:580px;
      //     height: 38px;
      //     background: repeating-linear-gradient(
      //         90deg,
      //         variables.$LightWood25 5px,
      //         variables.$DarkWood35 5px,
      //         variables.$LightWood25 9px,
      //         variables.$LightWood25 27px
      //     );
      // }
    }

    .tank_tile_progress {
      position: absolute;
      bottom: 0;
      background: variables.$RedWine;
      width: 100%;
      left: 0;
      border-bottom-left-radius: variables.$ButtonRadius;
      border-bottom-right-radius: variables.$ButtonRadius;
      z-index: 0;

      &_white {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        border-bottom-left-radius: variables.$ButtonRadius;
        border-bottom-right-radius: variables.$ButtonRadius;
        z-index: 0;
        background: variables.$WhiteWine;
      }
      &_rose {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        border-bottom-left-radius: variables.$ButtonRadius;
        border-bottom-right-radius: variables.$ButtonRadius;
        z-index: 0;
        background: variables.$RoseWine;
      }

      &_red {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        border-bottom-left-radius: variables.$ButtonRadius;
        border-bottom-right-radius: variables.$ButtonRadius;
        z-index: 0;
        background: variables.$RedWine;
      }
    }

    &_recap {
      border: 1px solid variables.$Black50;
    }
  }
}
