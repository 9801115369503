@use '../../../../styles/variables';


.recap {

    &.reverse {
        transform: rotate(180deg);

        .bottle_data {
            // display: flex;
            // justify-content: center;

            .bottle_icon {
                margin-bottom: 10px;
                
                .icon {
                    position: relative;
                    margin-left: 0;
                    transform: rotate(-45deg);
                }
            }
            
            .box_icon {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                
                .box_item {
                    position: relative;
                    margin-left: 0;
                    transform: rotate(-45deg);
                }
            }

            .lots {
                margin-top: 15px;
            }
        
            .normal {
                margin-left: 10px;
                font-weight: 400;
            }
        }    
    }
}
