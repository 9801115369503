@use "../../../styles/variables";

.label_field {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  min-width: 150px;
  
  &_label {
    font-size: 16px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    font-weight: 600;
    padding-bottom: 4px;
  }
  &_value {
    width: 100%;
    min-height: 32px;
    // border: 2px solid variables.$LightBlue;
    // background-color: variables.$GlassLightBlue25;
    // border-radius: 8px;
    padding: 10px 16px;
    height: 100%;

    &.notes {
      margin-left: 8px;
      border: 1px solid variables.$ShadowGray; 
      border-radius: 8px;
    }
  }


  /* Large or high resolution screen */
  @media all and (max-width: 1690px) {

  }

  /* Desktop or laptop */
  @media all and (min-width: 1280px) and (max-width: 1689px) {

  }

  // /* Tablet in horizontal view */
  // @media all and (min-width: 737px) and (max-width: 1279px) {
  // }

  // /* Tablet in vertical view */
  // @media all and (min-width: 481px) and (max-width: 736px) {
  // }

  /* Smartphone or small tablet */
  @media all and (max-width: 480px) {
    order: 1;
    flex-grow: 1;
    min-width: 115px;
  }
}

.input_field {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  min-width: 150px;
  margin-top: 10px;

  &.is_checkbox {
    min-width: 30px;
  }

  &.is_number {
    max-width: 60px;
  }

  label {
    height: 24px;
    font-size: 14px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    font-weight: 600;

    &.winemaking {
      margin-top: -20px;
      margin-left: 10px;
    }

    &.required {
      &::after {
        content: "\00a0*";
        color: variables.$RedError;
      }
    }
  }

  input {
    font-size: 16px;
    min-height: 44px;
    width: 100%;
    border-radius: 8px;
    // border: 2px solid variables.$LightBlue;
    padding: 10px 16px;
    outline: none;
    z-index: 5;
    border: 2px solid variables.$ShadowGray;
    background-color: variables.$SelectWhite;

    &::placeholder {
      color: variables.$Black50;
    }

    &.is_slider{
      min-height: 7px !important;
      padding: 0 !important;
    }
    
    &[type="number"] {
      &.small {     // TO DO apply only when is needed
        width: 120px;  
      }
    }

    &[type="checkbox"] {
      // margin-left: 50px;
      width: 16px;
      height: 16px;
      margin-top: -20px;
      margin-bottom: -20px;
      accent-color: variables.$RedWine;
      background-color: variables.$SelectWhite;
      font-size: 16px;
      outline: none;
      z-index: 5;
      background-color: variables.$SelectWhite;
    
      &.winemaking {
        margin-top: 1px;
        width: 44px;
        height: 44px;
      }

      &.confirm {
        margin-top: 4px;
        margin-bottom: 0px;
        width: 30px;
        height: 30px;
      }
    }

    &.disabled {
      background-color: variables.$Black25;
    }
  }
  
  .error_message {
    opacity: 0;
    height: 0;
    margin: -8px 8px 0 auto;
    font-size: 13px;
    border-radius: 8px;
    background-color: variables.$RedError;
    padding: 0 8px;
    transition: opacity variables.$TransitionTime,
      height variables.$TransitionTime variables.$TransitionMode,
      padding variables.$TransitionTime variables.$TransitionMode;
    width: auto;
    max-width: 180px;
    overflow: hidden;

    &.is_warning {
      background-color: variables.$WarningError;
      background-color: rgb(255, 165, 0);
    }
  }

  &.is_error {
    input {
      border: 2px solid variables.$RedError;
    }
    .error_message {
      opacity: 1;
      height: auto;
      // height: 31px;
      padding: 10px 8px 8px;
      color: variables.$White;
    }

    &.is_warning {
      input {
        border: 2px solid variables.$WarningError;
      }
    }
  }

  &.is_ok {
    input {
      border: 2px solid variables.$AddRowGreen;
    }
  }
}

.submit_field {
  width: 100%;

  input {
    margin: 20px auto;
    border-radius: 100px;
    text-align: center;
    padding: 12px 16px;
    background-color: variables.$White50;
    width: 100%;
    border: none;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: box-shadow variables.$TransitionTime variables.$TransitionMode,
      background variables.$TransitionTime variables.$TransitionMode,
      color variables.$TransitionTime variables.$TransitionMode;
    &:hover {
      box-shadow: variables.$CardShadow;
      background-color: variables.$White75;
    }
    &:active {
      background-color: variables.$White;
      box-shadow: variables.$CardShadow;
    }
    &:disabled {
      background: variables.$White25;
      color: variables.$White50;
      cursor: default;
    }
  }
}

.switch_field {
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  color: variables.$Black50;
  width: fit-content;
  &_left,
  &_right {
    transition: variables.$TransitionTime variables.$TransitionMode;
  }
  .active {
    color: variables.$Black;
  }

  .negative {
    color: variables.$White;
  }

  &_switch {
    position: relative;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    input {
      opacity: 0;
      width: 0;
      height: 0;
      margin: 0;
      &:checked + .switch_field_slider {
        background-color: variables.$Black50;
      }
      &:focus + .switch_field_slider {
        box-shadow: variables.$CardShadow;
      }
      &:checked + .switch_field_slider::before {
        transform: translate(16px);
      }
    }
  }

  &_slider {
    cursor: pointer;
    background-color: variables.$Black50;
    transition: variables.$TransitionTime variables.$TransitionMode;
    width: 100%;
    height: 2px;

    &::before {
      border-radius: 50%;
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 0px;
      bottom: 8px;
      background-color: variables.$LightRedWine;
      box-shadow: variables.$CardShadow;
      transition: variables.$TransitionTime variables.$TransitionMode;
    }
  }
}

.textarea_field {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 400px;
  height: 150px;

  &.is_chat {
    width: 80%;
    margin-left: 30px;
  }

  label {
    height: 24px;
    font-size: 14px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  textarea {
    width: 100%;
    height: 100%;
    resize: none;
    border: 2px solid variables.$ShadowGray;
    background-color: variables.$SelectWhite;
    padding: 10px 16px;
    outline: none;
    // background-color: #fff;
    border-radius: 8px;
    font-size: 16px;
  }
}

.select_field {
  &_controlled_container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    margin-bottom: 23px;
    min-width: 200px;
    
    label {
      height: 24px;
      font-size: 14px;
      margin-left: 8px;
      display: flex;
      align-items: center;
      font-weight: 600;

      &.required {
        &::after {
          content: "\00a0*";
          color: variables.$RedError;
        }
      }
    }

    &.is_table {
      margin: 2px 0;
    }

    .error_message {
      opacity: 0;
      height: 0;
      margin: -8px 8px 0 auto;
      font-size: 13px;
      border-radius: 8px;
      background-color: variables.$RedError;
      padding: 0 8px;
      transition: opacity variables.$TransitionTime,
        height variables.$TransitionTime variables.$TransitionMode,
        padding variables.$TransitionTime variables.$TransitionMode;
      width: auto;
      max-width: 500px;
      overflow: hidden;
    }

    &.is_error {
      .error_message {
        opacity: 1;
        height: auto;
        // height: 31px;
        padding: 10px 8px 8px;
        color: variables.$White;
      }
    }
  }

  &_wrapper {
    position: relative;

    &_container {
      font-size: 16px;
      min-height: 47px;
      width: 100%;
      border-radius: 8px;
      // border: 2px solid variables.$LightBlue;
      border: 2px solid variables.$ShadowGray;
      padding: 10px 16px;
      outline: none;
      background-color: variables.$SelectWhite;
      // background-color: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      &.disabled {
        cursor: default;
        color: variables.$Black50;
        font-weight: 400;
      }

      &.gray_filtered {
        color: variables.$Black50;
        font-weight: 400;
      }

      &.is_table {
        border: none;
        background-color: transparent;
        color: variables.$AddRowGreen;
      }

      &.is_error {
        border: 2px solid variables.$RedError;
      }
    }
  }
  &_options {
    &_wrapper {
      position: absolute;
      top: 90%;
      padding: 10px 16px;
      left: 16px;
      z-index: 14;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      min-width: 200px;
      overflow-y: auto;
      max-height: 250px;
      background-color: variables.$CardLightGray25;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: variables.$Blur30;
      box-shadow: variables.$CardShadow;
      border: 2px solid variables.$CardLightGray50;
    }
    &_option {
      padding: 8px;
      cursor: pointer;
      border-radius: 8px;
      position: relative;
      &:hover {
        // background-color: variables.$GlassLightBlue50;
        background-color: variables.$CardLightGray35;
      }
      &::after {
        position: absolute;
        bottom: 0;
        content: "";
        width: calc(100% - 12px);
        left: 50%;
        transform: translate(-50%);
        height: 1px;
        background-color: variables.$Black50;
      }
      &:last-child {
        &::after {
          height: 0;
          content: none;
        }
      }

      &.bold {
        font-weight: 600;
      }
    }
  }
}

.multiple_select_field {
  &_controlled_container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    min-width: 200px;
    margin-top: 18px;
  
    label {
      height: 24px;
      font-size: 14px;
      margin-left: 8px;
      display: flex;
      align-items: center;
      font-weight: 600;
    }
  }
  &_wrapper {
    position: relative;

    &_container {
      font-size: 16px;
      min-height: 47px;
      width: 100%;
      border-radius: 8px;
      // border: 2px solid variables.$LightBlue;
      padding: 10px 16px;
      outline: none;
      // background-color: #fff;
      border: 2px solid variables.$ShadowGray;
      background-color: variables.$SelectWhite;
      
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      &.gray_filtered {
        color: variables.$Black50;
      }
    }
  }
  &_options {
    &_wrapper {
      position: absolute;
      top: 90%;
      // background: variables.$GlassLightBlue50;
      padding: 10px 16px;
      left: 16px;
      z-index: 10;
      border-radius: 8px;
      backdrop-filter: variables.$Blur;
      display: flex;
      flex-direction: column;
      // border: 2px solid variables.$LightBlue;
      min-width: 200px;
      box-shadow: variables.$CardShadow;
    }
    &_option {
      padding: 8px;
      cursor: pointer;
      border-radius: 8px;
      position: relative;
      &:hover {
        background-color: variables.$GlassLightBlue50;
      }
      &::after {
        position: absolute;
        bottom: 0;
        content: "";
        width: calc(100% - 12px);
        left: 50%;
        transform: translate(-50%);
        height: 1px;
        background-color: variables.$Black50;
      }
      &:last-child {
        &::after {
          height: 0;
          content: none;
        }
      }
    }
  }
}

.slider {

  &.is-flex {
    display: flex;
  }

  .slider_text {
    text-align: center;
    padding-top: 1px;
  }

  .no-slide {
    width: 100%;
    height: 7px;
    background: variables.$Black25;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    border: none;
    border-radius: 5px;
    
    .progress {
      background: variables.$RedWine;
      
      &.red {
        background: variables.$RedWine;
      }
      
      &.white {
        background: variables.$WhiteWine;  
      }

      &.rose {
        background: variables.$RoseWine;  
      }
    }
  }

  input[type="range"] {
    -webkit-appearance: none;
    height: 7px;
    background: variables.$Black25;
    border: none;
    border-radius: 5px;
    background-image: linear-gradient(variables.$RedWine, variables.$RedError);
    background-repeat: no-repeat;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: variables.$RedWine;
    cursor: pointer;
    box-shadow: 0 0 2px 0 variables.$Black50;
    transition: background 0.3s ease-in-out;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input[type="range"]::-webkit-slider-thumb:hover {
    box-shadow: variables.$RedWine50 0px 0px 0px 8px;
  }

  input[type="range"]::-webkit-slider-thumb:active {
    box-shadow: #d46a6a50 0px 0px 0px 11px;
    transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.datepicker_field {

  &_controlled_container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    min-width: 200px;
    margin-top: 10px;
    // height: 47px;
    
    input {
      font-size: 16px;
      min-height: 47px;
      width: 100%;
      border-radius: 8px !important;
      border: 2px solid variables.$ShadowGray !important;
      padding: 10px 16px;
      outline: none;
      background-color: variables.$SelectWhite !important;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
    }
    
    label {
      height: 24px;
      font-size: 14px;
      margin-left: 8px;
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    &.is_table {
      margin: 2px 0;
    }

  .error_message {
    opacity: 0;
    height: 0;
    margin: -8px 8px 0 auto;
    font-size: 13px;
    border-radius: 8px;
    background-color: variables.$RedError;
    padding: 0 8px;
    transition: opacity variables.$TransitionTime,
      height variables.$TransitionTime variables.$TransitionMode,
      padding variables.$TransitionTime variables.$TransitionMode;
    width: auto;
    max-width: 500px;
    overflow: hidden;
  }

  &.is_error {
    .input_error {
      border: 2px solid variables.$RedError !important;
    }
    .error_message {
      opacity: 1;
      height: auto;
      // height: 31px;
      padding: 10px 8px 8px;
      color: variables.$White;
    }
  }
  }
}


.chatarea_field {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  margin-left: -16px;
  width: 70%;
  height: auto;


  /* Large or high resolution screen */
  @media all and (max-width: 1690px) {

  }

  /* Desktop or laptop */
  @media all and (min-width: 1280px) and (max-width: 1689px) {

  }

  // /* Tablet in horizontal view */
  // @media all and (min-width: 737px) and (max-width: 1279px) {
  // }

  // /* Tablet in vertical view */
  // @media all and (min-width: 481px) and (max-width: 736px) {
  // }

  /* Smartphone or small tablet */
  @media all and (max-width: 480px) {
    // width: 100%;
  }

  label {
    height: 24px;
    font-size: 16px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  .chat {
    display: block;
    flex-direction: column;
    min-width: 240px;
    overflow: hidden;
    margin-left: 16px;
  }

  .chat-item {
    // width: 100%;
    // // height: 100%;
    // resize: none;
    // border: 2px solid variables.$ShadowGray;
    // background-color: variables.$SelectWhite;
    // padding: 10px 16px;
    // outline: none;
    // // background-color: #fff;
    // border-radius: 8px;
    // font-size: 16px;
    // // color: red;

    // background: #fff;
    display: flex;
    flex-direction: row;
    height: auto;
    min-width: 240px;
    overflow: hidden;
    user-select: none;
    margin-bottom: 20px;

    &_avatar {
        padding: 0 10px 0 10px;
        position: relative;
    
        &_container {
          align-items: center;
          display: flex;
          justify-content: center;
          overflow: hidden;
        }
        &_container.large {
            height: 45px;
            width: 45px;
        }
        
        &_container.circle {
            border-radius: 100%;
        }
    }

    .chat-avatar {
      background: #ccc;
      border: none!important;
      border-radius: 100%;
      font-size: 10px;
      height: 40px;
      line-height: 40px;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 40px;
    }

    &_body {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      padding-right: 15px;
      margin-top: 10px;
      border-bottom: 1px solid variables.$ChatBorder;

      &_top {
        display: flex;

        &_title {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          font-weight: 500;
        }

        &_time {
          color: variables.$ChatTime;
          font-size: 12px;
        }
      }

      &_bottom {
        display: flex;
        margin-top: 4px;

        &_title {
          font-size: 15px;
          color: variables.$LightGray;
        }
      }
    }
  }

  .note-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
    .new-note-button {
      margin-left: auto;

      /* Large or high resolution screen */
      @media all and (max-width: 1690px) {

      }

      /* Desktop or laptop */
      @media all and (min-width: 1280px) and (max-width: 1689px) {

      }

      // /* Tablet in horizontal view */
      // @media all and (min-width: 737px) and (max-width: 1279px) {
      // }

      // /* Tablet in vertical view */
      // @media all and (min-width: 481px) and (max-width: 736px) {
      // }

      /* Smartphone or small tablet */
      @media all and (max-width: 480px) {
        // flex-wrap: wrap;
        margin-left: 0;
      }
  
        &.disabled {
          background-color: variables.$RedWine25;
          color: variables.$Black50;
          cursor: default;
      }
    }

    .new-note-tooltip {
      margin-left: auto;
    }

    .new-note-container {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;

      .note-button-container {
        gap: 10px;
        display: flex;
        flex-direction: column;
        width: 150px;
    
        button {
          height: fit-content;
          width: 100%;
          margin-left: auto;
        }
      }
    }
  }
}