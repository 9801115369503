@use "../../../../styles/variables";

.tank_icon {

  &.reverse {
    transform: rotate(180deg);
  }

  .tank_name {
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
  }

  .batch_name {
    text-align: center;
  }

  .error_message {
    opacity: 0;
    height: 0;
    margin: -8px 8px 0 auto;
    font-size: 13px;
    border-radius: 8px;
    background-color: variables.$RedError;
    padding: 0 8px;
    transition: opacity variables.$TransitionTime,
      height variables.$TransitionTime variables.$TransitionMode,
      padding variables.$TransitionTime variables.$TransitionMode;
    width: auto;
    max-width: 500px;
    overflow: hidden;
  }

  &.is_error {
    input {
      border: 2px solid variables.$RedError;
    }
    .error_message {
      opacity: 1;
      height: auto;
      // height: 31px;
      padding: 10px 8px 8px;
      color: variables.$White;
    }
  }

  .tank_info {
    text-align: center;
    min-width: 250px;
  }

  .tank_representation {
    width: 40px;
    height: 60px;
    background: variables.$GlassLightBlue;
    background: variables.$CardLightGray50;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 8px;

    &.focused {
        // width: 48px;
        // max-width: 48px;
        // height: 72px;
        zoom: 1.2;
    }
    // margin-top: 10px;
    // margin-left: 16px;

    display: flex;
    margin: 10px auto;
    max-width: 40px;
    margin-bottom: 10px;

    &.barrel {
      // width: 35px;
      // max-width: 35px;
      // height: 25px;
      // display: block;
      // background-color: variables.$LightWood25;
      border-radius: 100% / 20%;

      background: repeating-linear-gradient(
        0deg,
        variables.$LightWood25 5px,
        variables.$DarkWood35 5px,
        variables.$LightWood25 8px,
        variables.$LightWood25 30px
      );

      // &.tonneau {
      //     width: 58px;
      //     max-width:580px;
      //     height: 38px;
      //     background: repeating-linear-gradient(
      //         90deg,
      //         variables.$LightWood25 5px,
      //         variables.$DarkWood35 5px,
      //         variables.$LightWood25 9px,
      //         variables.$LightWood25 27px
      //     );
      // }
    }

    .tank_tile_progress {
      position: absolute;
      bottom: 0;
      background: variables.$RedWine;
      width: 100%;
      left: 0;
      border-bottom-left-radius: variables.$ButtonRadius;
      border-bottom-right-radius: variables.$ButtonRadius;
      z-index: 0;

      &_white {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        border-bottom-left-radius: variables.$ButtonRadius;
        border-bottom-right-radius: variables.$ButtonRadius;
        z-index: 0;
        background: variables.$WhiteWine;
      }
      &_rose {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        border-bottom-left-radius: variables.$ButtonRadius;
        border-bottom-right-radius: variables.$ButtonRadius;
        z-index: 0;
        background: variables.$RoseWine;
      }

      &_red {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        border-bottom-left-radius: variables.$ButtonRadius;
        border-bottom-right-radius: variables.$ButtonRadius;
        z-index: 0;
        background: variables.$RedWine;
      }
    }

    &_recap {
      border: 1px solid variables.$Black50;
    }
  }
}
