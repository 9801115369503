@use '../../../styles/variables';
.operation_form_wrapper {

    .arrow {
        width: 70px;
        text-align: center;
    }

    &_row {
        display: flex;
        gap: 16px;
        align-items: center;
        
        .button {
            margin-left: 10px;
        }
        
        .submit_field {
            margin-left: auto;
            input {
                background-color: variables.$RedWine50;
                &:hover {
                   background-color: variables.$RedWine75;
                    color: variables.$White;
                    box-shadow: variables.$CardShadow;
                }
                &:active {
                    background-color: variables.$RedWine;
                    color: variables.$White;
                    box-shadow: variables.$CardShadow;
                }
            }
        }

        &.vertical {
            flex-direction: column;
            align-items: baseline;
        }

        &.aside {
            row-gap: 50px;
            align-items: start;
        }
    }

    h6 {
        margin-top: 20px;
        margin-bottom: 0px;
    }
}