@use '../../../../styles/variables';

.cta, button {
    color: variables.$Black;
    padding: 12px 16px;
    border: none;
    background-color: variables.$Cta15;
    box-shadow: variables.$CardShadow;

    // background-color: variables.$Cta50;
    font-size: 14px;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 500;
    transition: box-shadow variables.$TransitionTime variables.$TransitionMode, 
    background variables.$TransitionTime variables.$TransitionMode,
    color variables.$TransitionTime variables.$TransitionMode;

    &.create {
        display: flex;
        gap: 5px;
        align-items: center;
    }
    
    &.height-100 {
        height: 100%;
    }

    &:hover {
        background-color: variables.$RedWine75;
        color: variables.$White;
        // box-shadow: variables.$CardShadow;
        box-shadow: variables.$HoverShadowCoord variables.$ShadowGray;
    }
    &:active {
        background-color: variables.$RedWine;
        color: variables.$White;
        box-shadow: variables.$CardShadow;
    }
    &:disabled {
        background-color: variables.$RedWine25;
        color: variables.$Black50;
        cursor: default;
    }
    &_red {
        background-color: variables.$White50;
        
        &:hover {
            background-color: variables.$White75;
        }
        &:active {
            background-color: variables.$White;
        }
        &:disabled {
                background: variables.$White25;
                color: variables.$White50;
                cursor: default
        }
    }

    &_full {
        width: 100%;
        background-color: variables.$White50;
        border: 2px solid variables.$RedWine75;
        // border-radius: 10px;

        &:hover {
            color: variables.$White;
            background-color: variables.$RedWine75;
        }
        &:active {
            background-color: variables.$White;
        }
        &:disabled {
                background: variables.$White25;
                color: variables.$White50;
                cursor: default
        }
    }
    
    &.large {
        font-size: 18px;
        width: 45%;
        padding: 16px 20px;
        /* Large or high resolution screen */
        @media all and (max-width: 1690px) {

        }

        /* Desktop or laptop */
        @media all and (min-width: 1280px) and (max-width: 1689px) {

        }

        // /* Tablet in horizontal view */
        // @media all and (min-width: 737px) and (max-width: 1279px) {
        // }

        // /* Tablet in vertical view */
        // @media all and (min-width: 481px) and (max-width: 736px) {
        // }

        /* Smartphone or small tablet */
        @media all and (max-width: 480px) {
            width: 65%;
        }
    }
    
    &.complete {
        background-color: variables.$Gold30;
        color: variables.$Black;
        
        &:hover {
            background-color: variables.$Gold75;
        }
        &:active {
            background-color: variables.$Gold;
        }
        &:disabled {
            background: variables.$Gold30;
            color: variables.$Black50;
            cursor: default
        }
    }

    &.add {
        background-color: variables.$GlassLightBlue50;
        color: variables.$Black;
        
        &:hover {
            background-color: variables.$GlassLightBlue;
        }
        &:active {
            background-color: variables.$GlassLightBlue;
        }
        &:disabled {
            background: variables.$GlassLightBlue25;
            color: variables.$Black50;
            cursor: default
        }
    }

    &.dialog {
        // color: variables.$Black50;
        background-color: variables.$White;
        width: 100%;
           
        &:hover {
            background-color: variables.$White75;
        }
        &:active {
            background-color: variables.$White;
        }
        &:disabled {
                background: variables.$White25;
                color: variables.$White50;
                cursor: default
        }

        &.confirm {
            border: 1px solid variables.$GrapeWineGreen;
            background: variables.$GrapeWineGreen;

            // &:hover {
            //     background-color: variables.$White75;
            // }
            // &:active {
            //     background-color: variables.$White;
            // }
            // &:disabled {
            //         background: variables.$White25;
            //         color: variables.$White50;
            //         cursor: default
            // }
        }

        &.deny {
            border: 1px solid variables.$RedError;

            &:hover {
                color: variables.$RedError;
            }
            // &:active {
            //     background-color: variables.$White;
            // }
            // &:disabled {
            //         background: variables.$White25;
            //         color: variables.$White50;
            //         cursor: default
            // }
        }
    }


    /* Large or high resolution screen */
    @media all and (max-width: 1690px) {

    }

    /* Desktop or laptop */
    @media all and (min-width: 1280px) and (max-width: 1689px) {

    }

    // /* Tablet in horizontal view */
    // @media all and (min-width: 737px) and (max-width: 1279px) {
    // }

    // /* Tablet in vertical view */
    // @media all and (min-width: 481px) and (max-width: 736px) {
    // }

    /* Smartphone or small tablet */
    @media all and (max-width: 480px) {
        padding: 8px 11px;
    }
}